import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './style.css';
import 'vue-final-modal/style.css';
import {createI18n} from "vue-i18n";
import it from './locales/it.json';
import {createVfm} from "vue-final-modal";
import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

const app = createApp(App);

app.provide('appName', process.env.VUE_APP_NAME);

app.use(ToastPlugin);
app.use(router);

const vfm = createVfm()
app.use(vfm)

const i18n = createI18n({
    legacy: false,
    locale: 'it',
    fallbackLocale: 'it',
    messages: {
        it: it
    }
})

app.use(i18n);


router.beforeEach((to, from, next) => {
  if (!to.meta.requiresAuth) {
    next();
    return;
  }

  if (!localStorage.getItem('user')) {
      next({ name: 'login' })
  } else {
      next()
  }
})

app.mount('#app');
