<script>
  import axios from "axios";
  import {ref} from "vue";
  import Loading from 'vue3-loading-overlay';
  import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
  import OdsBox from "@/components/OdsBox.vue";

  export default {
    components: {
      OdsBox,
      Loading,
    },
    props: {
      title: String,
      orderapitype: String,
      orderstatus: String,
      modaltitle: String,
      modalbody: String,
    },
    setup() {
      const auth = {
        username: process.env.VUE_APP_API_USER,
        password: process.env.VUE_APP_API_PASSWORD
      };
      let message = ref("");
      let ordini = ref([]);
      const isLoading = ref(false);
      const user = JSON.parse(localStorage.getItem('user'));

      return { message, ordini, isLoading, user, auth }
    },
    async mounted() {
      this.isLoading = true;

      if (this.user) {
        let operatorId = this.user[0].operatore?.id;
        let comuneId = this.user[0].operatore?.comune?.id;

        if (operatorId === undefined) {
          operatorId = 9999999
        }

        const res = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/getoperatorods?operatoreId='+operatorId+'&type='+this.orderapitype+'&comuneId='+comuneId, {auth: this.auth})

        if (res.data.status === 'OK') {
          if (res.data.data !== []) {
            this.ordini = res.data.data;
            this.isLoading = false;
          } else {
            this.message = this.$t('ods_list.no_orders');
            this.isLoading = false;
          }
        } else {
          this.message = res.data.message;
          this.isLoading = false;
        }
      } else {
        await this.$router.push('/login');
        this.isLoading = false;
      }
    },
  }
</script>

<template>
  <div class="vld-parent">
    <Loading :active="isLoading" :is-full-page="true"></Loading>
  </div>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-4 lg:px-8 mb-[5vh]">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 class="my-3 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">{{title}}</h2>
    </div>
    <div v-if="message !== ''" class="text-center h-[50vh] font-bold text-gray-500 content-center grid text-[1.6rem]">
      <p>{{message}}</p>
    </div>
    <div v-else class="w-full">
      <ul>
        <li v-for="(ordine, index) in ordini" :key="index" class="w-full border border-black my-3 p-3">
          <OdsBox :ordine='ordine' :modaltitle="this.modaltitle" :modalbody="this.modalbody" :orderstatus="this.orderstatus"></OdsBox>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>
