import {createRouter, createWebHistory} from 'vue-router'
import HomeView from "@/views/HomeView.vue";

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: HomeView,
            meta: {
                requiresAuth: true
            }
        },
        {
            name: 'login',
            path: '/login',
            component: () => import('@/views/LoginView.vue'),
        },
        {
            path: '/ods/:orderId',
            component: () => import('@/views/OdsView.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/closedods',
            component: () => import('@/views/ClosedOdsView.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/allods',
            component: () => import('@/views/AllOdsView.vue'),
            meta: {
                requiresAuth: true
            }
        }
    ],
})
