<script lang="ts">
import {VueFinalModal} from "vue-final-modal";

export default {
  components: {VueFinalModal},
  props: {
    title: String,
  }
}
</script>

<template>
  <VueFinalModal
      class="flex justify-center items-center"
      content-class="flex flex-col max-w-xl mx-4 p-4 bg-white rounded-lg space-y-2"
  >
    <h1 class="text-xl">
      {{ title }}
    </h1>
    <slot />
    <div class="w-full grid gap-x-4 grid-cols-2 h-[5vh]">
      <button class="border rounded-lg text-white hover:bg-red-600 bg-red-700" @click="$emit('cancel')">
        {{$t('ods_list.cancel')}}
      </button>
      <button class="border rounded-lg text-white hover:bg-green-600 bg-green-700" @click="$emit('confirm')">
        {{$t('ods_list.confirm')}}
      </button>
    </div>
  </VueFinalModal>
</template>
