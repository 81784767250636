<script>
import moment from "moment";
import ModalConfirm from "@/components/ModalConfirm.vue";
import axios from "axios";
import {useModal} from "vue-final-modal";

export default {
  props: {
    ordine: Object,
    orderstatus: String,
    modaltitle: String,
    modalbody: String,
  },
  setup(props) {
    const user = JSON.parse(localStorage.getItem('user'));
    const auth = {
      username: process.env.VUE_APP_API_USER,
      password: process.env.VUE_APP_API_PASSWORD
    };
    const navigatorTest = process.env.VUE_APP_NAVIGATOR_TEST;

    async function changeOdsStatus(id, status, urlNavigate='') {
      try {
        await axios.post(process.env.VUE_APP_API_ENDPOINT + '/changeodsstatus', {
          id: id,
          status: status
        }, {auth: auth})

        if (urlNavigate.length>0) {
          await goToNavigator(id, navigatorTest);
          //location.reload();
        }
      } catch (e) {
        console.log(e)
      }
    }

    async function goToNavigator(id, navigatorTest) {
      await startSendingLocation(id);
      const link = document.createElement('a');
      link.href = 'navigator://start/'+navigatorTest+'/'+id;
      link.style.display = 'none'; // Rende l'elemento non visibile
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Pulisce aggiungendo e rimuovendo l'elemento
    }

    async function startSendingLocation(idOrdine) {
      if (!('geolocation' in navigator)) {
        alert('Geolocalizzazione non supportata.');
        return;
      }

      const sendLocationToAPI = async () => {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const pos = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
            timestamp: position.timestamp,
            idOrdine: idOrdine
          }
          try{
            await axios.post(process.env.VUE_APP_API_ENDPOINT + '/addodsgps', {
              position: pos
            }, {auth: auth})
          } catch (e) {
            console.log(e.message);
          }
        }, (error) => {
          console.error('Errore nella geolocalizzazione:', error);
        });
      };

      await sendLocationToAPI(); // Esegui il primo invio della posizione

      setInterval(sendLocationToAPI, 5 * 60 * 1000); // Invia la posizione ogni 5 minuti

    }

    async function assignOds(idOperatore, idOds) {
      try {
        await axios.post(process.env.VUE_APP_API_ENDPOINT + '/assignods', {
          idOperatore: idOperatore,
          idOds: idOds
        }, {auth: auth})
      } catch (e) {
        console.log(e)
      }
    }


    const { open, close } = useModal({
      component: ModalConfirm,
      attrs: {
        title: props.modaltitle,
        async onConfirm() {
          if (props.orderstatus !== 'all') {
            await changeOdsStatus(props.ordine.id, props.orderstatus);
            await location.reload();
          } else {
            await assignOds(user[0].operatore.id, props.ordine.id);
            await location.reload();
          }
          await close()
        },
        onCancel() {
          close()
        }
      },
      slots: {
        default: props.modalbody,
      },
    })

    return { open, navigatorTest, changeOdsStatus, user }
  },
  computed: {
    showNavigateButton() {
      if (this.ordine.status === 'closed') {
        return false
      }
      return this.ordine.operatore.id === this.user[0].operatore.id;

    },
  },
  methods: {
    getFormattedDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    getStatus(status) {
      if (status === 'closed') {
        return this.$t('status.closed');
      } else if(status === 'open') {
        return this.$t('status.open');
      } else {
        return this.$t('status.in_progress');
      }
    }
  }
}
</script>

<template>
  <div class="w-full">
    <div class="w-full grid grid-cols-3">
      <p class="justify-start grid">
        <span class="font-bold">{{$t('ods_list.number')}}:</span> {{ordine.id}}
      </p>
      <p class="justify-start grid">
        <span class="font-bold">{{$t('ods_list.date')}}:</span> {{getFormattedDate(ordine.data.date)}}
      </p>
      <p class="justify-start grid">
        <span class="font-bold">{{$t('ods_list.vehicle')}}:</span> {{ordine.automezzo.descrizione}} - {{ordine.automezzo.seriale}}
      </p>
    </div>
    <div class="w-full grid grid-cols-3 mt-6">
      <p class="justify-start grid">
        <span class="font-bold">{{$t('ods_list.status')}}:</span> {{getStatus(ordine.status)}}
      </p>

      <p class="justify-start grid">
        <span class="font-bold">{{$t('ods_list.place')}}:</span> {{ordine.comune.descrizione}}
      </p>
      <p class="justify-start grid">
        <span class="font-bold">{{$t('ods_list.waste')}}:</span> {{ordine.tipoRifiuto.descrizione}}
      </p>
    </div>
  </div>
  <div class="w-full grid grid-cols-2 gap-x-4 my-3 h-[5vh]">
    <button v-if="ordine.status === 'closed'" @click="() => open()" class="w-full hover:bg-red-600 rounded-md bg-red-700 text-center content-center grid font-bold text-white">
      {{$t('ods_list.open')}}
    </button>
    <button v-else-if="this.orderstatus === 'all'" @click="() => open()" class="w-full hover:bg-red-600 rounded-md bg-red-700 text-center content-center grid font-bold text-white">
      {{$t('ods_list.assign')}}
    </button>
    <button v-else @click="() => open()" class="w-full hover:bg-red-600 rounded-md bg-red-700 text-center content-center grid font-bold text-white">
      {{$t('ods_list.close')}}
    </button>
    <div v-if="showNavigateButton" @click.prevent="changeOdsStatus(ordine.id, 'in_progress', 'deeplink')" class="w-full h-full hover:bg-green-600 rounded-md bg-green-700 text-center content-center grid cursor-pointer">
<!--      <a v-if="showNavigateButton" :href="'navigator://start/'+navigatorTest+'/'+ordine.id" class="w-full h-full hover:bg-green-600 rounded-md bg-green-700 text-center content-center grid">-->
        <p class="font-bold text-white">{{$t('ods_list.navigate')}}</p>
<!--      </a>-->
    </div>
  </div>
  <div class="w-full h-[5vh]">
    <router-link :to="'/ods/'+ordine.id" class="w-full h-full hover:bg-blue-600 rounded-md bg-blue-700 text-center content-center grid">
      <p class="font-bold text-white">{{$t('ods_list.detail')}}</p>
    </router-link>
  </div>
</template>

<style scoped>

</style>
