import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-xl" }
const _hoisted_2 = { class: "w-full grid gap-x-4 grid-cols-2 h-[5vh]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, {
    class: "flex justify-center items-center",
    "content-class": "flex flex-col max-w-xl mx-4 p-4 bg-white rounded-lg space-y-2"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString($props.title), 1),
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "border rounded-lg text-white hover:bg-red-600 bg-red-700",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
        }, _toDisplayString(_ctx.$t('ods_list.cancel')), 1),
        _createElementVNode("button", {
          class: "border rounded-lg text-white hover:bg-green-600 bg-green-700",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm')))
        }, _toDisplayString(_ctx.$t('ods_list.confirm')), 1)
      ])
    ]),
    _: 3
  }))
}